import * as React from "react";

export default function useBalanceBanter() {
  const [isClient, setClient] = React.useState(false);
  const [data, setData] = React.useState({ title: "", url: null });
  const [, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  React.useEffect(() => {
    function getData() {
      setLoading(true);
      fetch(`/.netlify/functions/get-latest-balance-banter`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
        })
        .catch((err) => {
          // error
          setError(true);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (isClient) {
      getData();
    }
  }, [isClient]);

  return { isClient, data, error };
}
