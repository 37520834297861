import * as React from "react";
import { isTablet, isMobile } from "react-device-detect";
import { FaRegPlayCircle } from "@react-icons/all-files/fa/FaRegPlayCircle";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";
import useBalanceBanter from "../../hooks/useBalanceBanter";

const isTouch = isMobile || isTablet;

export default function BalanceBanter() {
  const { isClient, data } = useBalanceBanter();
  const [play, setPlay] = React.useState(false);
  const isTouchOrMobile = isTouch || isMobile;

  const video = data?.url && (
    <EmbedYouTubeVideo
      title={data.title}
      url={data.url}
      autoplay
      wrapperHeight="100%"
    />
  );

  const spinner = (
    <div className="spinner-container">
      <FaSpinner className="fa-spin" size={40} style={{ color: "#fff" }} />
    </div>
  );

  const videoComponent = isTouchOrMobile
    ? video
    : !isClient
    ? spinner
    : play === false
    ? null
    : video;

  const playButton = !isTouchOrMobile && isClient && play === false && (
    <div className="spinner-container">
      <button
        className="banner-play-button"
        onClick={() => setPlay(true)}
        aria-label="Click or tap to play video"
      >
        <FaRegPlayCircle aria-hidden="true" />
      </button>
    </div>
  );

  return (
    <div className="signup-balance-banter">
      <div className="text-container">
        <div className="title-container">
          <h3 className="slogan">Lastly, you'll get</h3>
          <h2 className="title">Support!</h2>
        </div>
        <p className="description">
          Whether it's with my weekly #BALANCE Banter videos, a personal Zoom
          meeting, our live #BALANCE Happy Hour events, monthly fitness
          challenges, or our members only Facebook group, YOU ARE NOT ALONE!
          #BALANCE is a community!
        </p>
      </div>
      <div className="video-container">
        {playButton}
        {videoComponent}
      </div>
    </div>
  );
}
